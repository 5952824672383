import { DASHBOARD_ROUTE, MY_STABLE_ROUTE } from './routes';

// Dashboard
export const PROFILE = 'profile';
export const HORSE_CLAIM = 'horse_claim';
export const HORSE_CLAIM_STAR = 'horse_claim_star';
export const BUSINESS_PROFILE = 'business_profile';
export const FIND_THOROUGHBRED = 'find_thoroughbred';
export const HORSE_PLACEMENT = 'horse_placement';
export const HORSE_PLACEMENT_REJECTED = 'horse_placement_rejected';
export const ACK_RETRAINER = 'ack_retrainer';
export const ACK_RETIREMENT = 'ack_retirement';
export const ACK_FOSTER = 'ack_foster';
export const ACK_RETIREMENT_REJECTED = 'ack_retirement_rejected';
export const SPONSORSHIP_OUTCOME_REJECTED = 'sponsorship_outcome_rejected';
export const OHEP_NOTIFICATION = 'ohep_nomination';
export const OHEP_EOI_NOTIFICATION = 'ohep_eoi';
export const RESET_NOTIFICATION = 'reset_nomination';
export const RESET_WEEKLY_PROGRESS = 'reset_weekly_progress';
export const RESET_INITIAL_ASSESSMENT = 'reset_initial_assessment';
export const RESET_RESET_IN_TRAINING_DUE = 'reset_in_training_due';
export const RESET_RESET_IN_TRAINING_OVERDUE = 'reset_in_training_overdue';
export const RESET_RESET_SPELLING_OVERDUE = 'reset_spelling_overdue';
export const RESET_RESET_REHABILITATION_OVERDUE =
	'reset_rehabilitation_overdue';
export const SHARE_INFORMATION = 'share_information';
export const SHARE_INFORMATION_REJECTED = 'share_information_rejected';
export const HORSE_PLACEMENT_INTEREST = 'horse_placement_interest';
export const DISMISS = 'dismissable';
export const RETRAINING_REPORT = 'retraining_report';
export const OHEP_EOI_FORM_NAME = 'OhepEoiForm';
export const REMOVE_COMPETITION_ADMIN = 'remove_competition_admin';
export const COMPETITION_ADMIN_LINK = '/contacts';
export const IS_REMOVE_IDENTIFIER = 'isRemove';
export const COMPETITION_ADMIN_EXIST = 'competitionAdminExist';
export const ACK_FOSTER_EXIST = 'ackFosterExist';
export const REMOVE_ACK_FOSTER = 'remove_ack_foster';
export const THOROUGHBRED_UNREGISTERED = 'thoroughbred_unregistered';

export const RETIREMENT_REPORT_OVERDUE = 'retirement_report_overdue';

export const RETIREMENT_REPORT_DUE = 'retirement_report_due';

export const HORSE_NAME = 'horseName';

export const DRAFT = 'Draft';
export const COMPLETE = 'COMPLETE';
export const REVIEW = 'Review';
export const APPROVED = 'Approved';
export const REJECTED = 'Rejected';
export const REVIEWED = 'Reviewed';
export const REMOVED = 'Removed';

export const DECLINED = 'Declined';

export const CLAIM = 'CLAIM';
export const FULL_CIRCLE = 'full_circle';
export const RETRAINER = 'RETRAINER';
export const FOLLOWING = 'FOLLOWING';
export const RESET = 'RESET';
export const FOSTER = 'STAR';
export const ACKNOWLEDGED_RETIREMENT = 'Acknowledged Retirement';
export const RESET_EXPRESS = 'RESET Express';
export const ACKNOWLEDGED_REHOMING = 'Acknowledged Rehoming';

export const COMPETITION_ADMIN = 'competition_admin';

export const COMPETITIONS = 'Competitions';

export const COMPETITION_LINK = '/competitions';

// ROLES
export const ROLE_OTT_COMMUNITY = 'ROLE_OTT_COMMUNITY'; //Default Role for all users that register
export const ROLE_RSPCA_REPRESENTATIVE = 'ROLE_RSPCA_REPRESENTATIVE'; //RSPCA Representative
export const ROLE_SPECIALISED_FACILITY = 'ROLE_SPECIALISED_FACILITY'; //Specialised Facility
export const ROLE_MOUNTED_POLICE_FORCE = 'ROLE_MOUNTED_POLICE_FORCE'; //Mounted Police Force
export const ROLE_RV_ACKNOWLEDGED_RETRAINER = 'ROLE_RV_ACKNOWLEDGED_RETRAINER'; //RV Acknowledged Retrainer
export const ROLE_ACKNOWLEDGED_RESET_RETRAINER =
	'ROLE_ACKNOWLEDGED_RESET_RETRAINER'; //RV Acknowledged Reset Retrainer
export const ROLE_ACKNOWLEDGED_FOSTER_CARER = 'ROLE_ACKNOWLEDGED_FOSTER_CARER'; //Acknowledged Foster Carer
export const ROLE_ACKNOWLEDGED_RETIREMENT_FARM =
	'ROLE_ACKNOWLEDGED_RETIREMENT_FARM'; //Acknowledged Retirement Farm
export const ROLE_RV_EQUINE_WELFARE = 'ROLE_RV_EQUINE_WELFARE'; //Admin Function - Internal RV staff'
export const ROLE_NON_RV_VET = 'ROLE_NON_RV_VET'; //Non RV Vet
export const ROLE_RV_VET = 'ROLE_RV_VET'; //RV Vet
export const ROLE_EQUESTRIAN_ORGANISATION = 'ROLE_EQUESTRIAN_ORGANISATION';
export const ROLE_COMPETITION_ADMIN = 'ROLE_COMPETITION_ADMIN';
export const ROLE_TRANSPORTATION = 'ROLE_TRANSPORTATION';
export const ROLE_KNACKERY_REMOVAL = 'ROLE_KNACKERY_REMOVAL';
export const ROLE_MERCHANDISE_SUPPLIER = 'ROLE_MERCHANDISE_SUPPLIER';
export const ROLE_REHOMER = 'ROLE_ACKNOWLEDGED_REHOMER'; //Admin Function - Internal RV staff'

export const ACK_RETRAINER_TEXT = 'Acknowledged Retrainer';
export const ACK_RETIREMENT_FARM = 'Acknowledged Retirement Farm';
export const ACK_FOSTER_PROVIDER = 'Acknowledged STAR Provider';
export const ACK_REHOMER = 'Acknowledged Rehomer';

// Global Search Toggle
export const MULTIPLE_HORSES = ' MULTIPLE_HORSES';
export const THOROUGHBRED = 'THOROUGHBRED';
export const ACKNOWLEDGED_RETRAINER = 'ACKNOWLEDGED_RETRAINER';
export const AVAILABLE_FOR_RETRAINING = 'AVAILABLE_FOR_RETRAINING';
export const ACKNOWLEDGED_FOSTER_FARMS = 'ACKNOWLEDGED_FOSTER_FARMS';
export const ACKNOWLEDGED_RETIREMENT_FARMS = 'ACKNOWLEDGED_RETIREMENT_FARMS';
export const EVENTS = 'EVENTS';

//ContentType
export const MP4 = 'video/mp4';
export const JPEG = 'image/jpeg';
export const PNG = 'image/png';

//Horse Retrain Status
export const FOR_SALE = 'FOR_SALE';
export const IN_WORK = 'IN_WORK';
export const SPELLING = 'SPELLING';
export const REHABILITATION = 'REHABILITATION';
export const EUTHANISED = 'EUTHANISED';
export const SOLD = 'SOLD';
export const OWN_USE = 'OWN_USE';
export const OWN_USE_FUNDED = 'OWN_USE_FUNDED';
export const RETURN_TO_USER = 'RETURN_TO_USER';
export const OWN_USE_LEASED = 'OWN_USE_LEASED';

//Path Variables
export const WHITELISTURL = ['/account', DASHBOARD_ROUTE];
export const RETRAINERWHITELISTURL = [MY_STABLE_ROUTE];
export const ADMINWHITELISTURL = ['/review-list'];

//Tack Room
// Member
export const SHOW_ALL = 'Show All';
export const OTT_HEALTH_HUB = 'OTT Health Hub';
export const RIDING_AND_SCHOOLING = 'Riding & Schooling';
export const COMPETING_YOUR_OTT = 'Competing Your OTT';
export const FOR_THE_LOVE_OF_OTT = 'For the love of OTT';
export const BUYING_SELLING_AN_OTT = 'Buying & Selling An OTT';

//Non-Member and Member
export const OTT_EXPLAINED = 'OTT�s Explained';
export const NEWS_FEED = 'News Feed';
export const TACK_ROOM = 'Tack Room';

//Member
//Relationships
export const RELATIONSHIP_ORGANISATION = 'Organisation';
export const RELATIONSHIP_RETRAINER = 'Retrainer';
export const RELATIONSHIP_FOSTER = 'Acknowledged STAR Provider';

// Text character sizes
export const REASON_FOR_REJECTION_SIZE = 300;

export const CUSTOM_MULTI_STYLES = {
	multiValue: (provided, state) => {
		const opacity = state.isDisabled ? 0.5 : 1;
		const transition = 'opacity 300ms';

		const color = '#fff';
		const backgroundColor = '#006da6';
		return { ...provided, opacity, transition, color, backgroundColor };
	},
	multiValueLabel: (provided) => {
		const color = '#fff';
		return { ...provided, color };
	},
	control: (provided, state) => ({
		...provided,
		borderColor: state.selectProps.border
	})
};

export const HORSE_IDENTIFICATION_POINTS_ALLOCATION = {
	DAM_NAME: 20,
	SIRE_NAME: 20,
	MARKINGS_PHOTO: 10,
	MICROCHIP_NUMBER: 40,
	FOAL_YEAR: 10,
	BRAND_IMAGES: 40,
	MICROCHIP_IMAGES: 40
};

//REDIRECT
export const RETRAINER_SEARCH = 'retrainer-search';

export const DEFAULT_COUNTRY = 'Australia';

// Validation Messsages
export const INVALID_EMAIL_MESSAGE =
	'Email must be a valid email address, please check for spaces or other characters';

export const OHEP_FORMS = {
	OHEP_NOMINATION: 'OHEP Nomination',
	OHEP_EOI: 'OHEP EOI'
};

export const TRACEABILITY_FORMS = {
	FIND_YOUR_THOROUGHBRED: 'Find Your Thoroughbred',
	THOROUGHBRED_UNREGISTERED_IN_AUSTRALIA:
		'Thoroughbred unregistered in Australia',
	DECEASED_THOROUGHBRED_UPDATE: 'Deceased Thoroughbred Update'
};

export const RESET_FORMS = {
	RESET_NOMINATION: 'RESET Nomination',
	RESET_WEEKLY_PROGRESS: 'RESET Weekly Progress',
	RESET_INITIAL_ASSESSMENT: 'RESET Initial Assessment',
	RESET_EXPRESS_INITIAL_ASSESSMENT: 'RESET 2 Initial Assessment',
	RESET_EXPRESS_REHOME_REPORT: 'RESET 2 Ready for Rehoming Report',
	RESET_EXPRESS_REHABILITATION_REPORT:
		'RESET 2 Rehabilitation Conclusion Report',
	RESET_EXPRESS_REHOME_REPORT_2: 'RESET 2 Ready for Rehome Report'
};

export const RETIREMENT_FORMS = {
	RETIREMENT_INITIAL_ASSESSMENT: 'Retirement Initial Assessment',
	RETIREMENT_QUARTERLY_ASSESSMENT: 'Retirement Quarterly Assessment'
};

export const FOSTER_FORMS = {
	FOSTER_INITIAL_ASSESSMENT: 'STAR Initial Assessment',
	FOSTER_WEEKLY_ASSESSMENT: 'STAR Weekly Assessment',
	STAR_INITIAL_ASSESSMENT: 'Foster Initial Assessment',
	STAR_WEEKLY_ASSESSMENT: 'Foster Weekly Assessment'
};

export const SHARE_INFORMATION_FORM = 'Share Information';

export const AVAILABLE_FOR_RETRAINING_FORM = 'Available For Retraining';

export const FULL_CIRCLE_FORM = 'Full Circle';

export const RETRAINER_NOTIF = {
	ACK_RETRAINER_APPLICATION_APPROVED:
		'Acknowledged Retrainer Application has been successful',
	ACK_RETRAINER_APPLICATION_DECLINED:
		'Acknowledged Retrainer has been declined',
	BUSINESS_PROFILE_REQUEST_REJECTED: 'Acknowledged Retrainer',
	HORSE_AVAIABLE_FOR_RETRAINING:
		'There is a new horse Available for Retraining',
	ACK_RETRAINER_SUBMITTED_REPORT: 'Please submit your monthly Retrainer Report'
};

export const FOSTER_INITIAL_NOTIFICATION_TYPE = 'foster_initial_assessment';
export const FOSTER_WEEKLY_NOTIFICATION_TYPE = 'foster_weekly_assessment';

export const RESET_EXPRESS_INITIAL_NOTIFICATION_TYPE =
	'reset_express_initial_assessment';
export const RESET_EXPRESS_REHOME_NOTIFICATION_TYPE =
	'reset_express_rehome_assessment';
export const RESET_EXPRESS_REHABILITATION_NOTIFICATION_TYPE =
	'reset_express_rehabilitation_assessment';

export const MOBILE_SCREEN_SIZES = ['xs', 'sm', 'md'];
export const LG_TO_XL_SCREEN_SIZES = ['lg', 'xl'];
export const XXL_SCREEN_SIZE = ['xxl'];

export const BLANK_OPTION = {
	id: 0,
	value: ''
};

export const WORD_DOC_MIME_TYPE =
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

export const OTT_INFO_MAIL = 'info@ottcommunity.com.au';
export const OTT_INFO_PHONE = '03 9258 4258';

//Horse OTT Status
export const HORSE_AVAILABILITY_LISTED_STATUS = 6;
export const HORSE_AVAILABILITY_PENDING_STATUS = 7;
export const HORSE_AVAILABILITY_REMOVED_STATUS = 8;

export const HORSE_AVAILABILITY_ELIGIBLE_STATUS = 10;
export const HORSE_AVAILABILITY_IN_RETRAINING_STATUS = 13;
export const HORSE_AVAILABILITY_SPELLING_STATUS = 14;
export const HORSE_AVAILABILITY_FOR_SALE_STATUS = 16;
export const HORSE_AVAILABILITY_IN_REVIEW_STATUS = 12;
export const HORSE_AVAILABILITY_REHABILITATION_STATUS = 15;
export const HORSE_AVAILABILITY_ALLOCATING_TO_RETRAINER_STATUS = 18;

export const HORSE_AVAILABILITY_LISTED_BCTS_STATUS = 31;
export const HORSE_LISTED_B1 = 37;
export const HORSE_LISTED_B2 = 38;
export const HORSE_LISTED_C = 39;
export const HORSE_REMOVED_LISTING = 40;
export const HORSE_IN_RETRAINING = 41;
export const HORSE_SPELLING = 42;
export const HORSE_REHABILITATION = 43;
export const HORSE_READY_FOR_REHOMING = 44;
export const HORSE_CONCLUDE_RESET_EXPRESS = 45;

export const AVAILABLE_FOR_RETRAINING_LISTINGS = [
	HORSE_AVAILABILITY_LISTED_STATUS,
	HORSE_AVAILABILITY_PENDING_STATUS,
	HORSE_AVAILABILITY_LISTED_BCTS_STATUS
];

//Horse OTT Status RESET Eligible Listings
export const RESET_ELIGIBLE_LISTINGS = [
	HORSE_AVAILABILITY_ELIGIBLE_STATUS,
	HORSE_AVAILABILITY_IN_RETRAINING_STATUS,
	HORSE_AVAILABILITY_SPELLING_STATUS,
	HORSE_AVAILABILITY_FOR_SALE_STATUS,
	HORSE_AVAILABILITY_IN_REVIEW_STATUS,
	HORSE_AVAILABILITY_REHABILITATION_STATUS,
	HORSE_AVAILABILITY_ALLOCATING_TO_RETRAINER_STATUS
];

//Horse OTT Status for RESET Express
export const RESET_HORSE_EXPRESS = [
	HORSE_LISTED_B1,
	HORSE_LISTED_B2,
	HORSE_LISTED_C,
	HORSE_REMOVED_LISTING,
	HORSE_IN_RETRAINING,
	HORSE_SPELLING,
	HORSE_REHABILITATION,
	HORSE_READY_FOR_REHOMING,
	HORSE_CONCLUDE_RESET_EXPRESS
];

export const RESET_HORSE_EXPRESS_ONGOING_STATUS = [
	HORSE_IN_RETRAINING,
	HORSE_SPELLING,
	HORSE_REHABILITATION,
	HORSE_READY_FOR_REHOMING,
	HORSE_CONCLUDE_RESET_EXPRESS
];

export const RESET_HORSE_EXPRESS_ASSIGNED_STATUS = [
	HORSE_REMOVED_LISTING,
	HORSE_IN_RETRAINING,
	HORSE_SPELLING,
	HORSE_REHABILITATION,
	HORSE_READY_FOR_REHOMING,
	HORSE_CONCLUDE_RESET_EXPRESS
];

export const API_KEY = 'API_KEY';
export const AMAZON_COGNITO_USER_POOLS = 'AMAZON_COGNITO_USER_POOLS';

export const APPSYNC_CONFIG = {
	aws_appsync_graphqlEndpoint: process.env.GATSBY_GRAPHQL_ENDPOINT,
	aws_appsync_apiKey: process.env.GATSBY_GRAPHQL_API_KEY
};

export const APIS_CONFIG = {
	endpoints: [
		{
			name: 'AuAddressAutoAPI',
			endpoint:
				'https://api.addressfinder.io/api/au/address/autocomplete/?format=json&gnaf=1'
		},
		{
			name: 'AuAddressMetaAPI',
			endpoint:
				'https://api.addressfinder.io/api/au/address/metadata/?format=json&au_paf=0&gps=0'
		}
	]
};

export const IS_LOGGED_IN = 'isLoggedIn';

export const FOLLOWING_STATUS = {
	IS_FOLLOWING: 'Following',
	NOT_FOLLOWING: 'Follow'
};

export const EVENTS_LABEL = {
	EDIT_EVENT: 'Edit Event',
	ADD_EVENT: 'Add Event',
	UPDATE_EVENT: 'Update Event',
	SUBMIT_EVENT: 'Submit Event',
	PUBLISH_EVENT: 'Publish Event',
	APPROVED: 'Approved',
	SAVE_DRAFT: 'Save Draft'
};

// HTML IDs
/** Common Page **/
export const CommonKeys = {
	ACCOUNT: 'account',
	ACKNOWLEDGED_RETIREMENT_FARMS: 'acknowledged_retirement_farms',
	ACKNOWLEDGED_RETRAINERS: 'acknowledged_retrainers',
	ADMIN: 'admin',
	CANCEL: 'cancel',
	CANCEL_APPLICATION: 'cancel_application',
	CONFIRM: 'confirm',
	CLEAR_FORM: 'clear_form',
	CLOSE_TOAST: 'close_toast',
	COMMUNITY_CONTRIBUTORS: 'community_contributors',
	COMPLETE_FORM: 'complete_form',
	COMPLETE: 'complete',
	DASHBOARD: 'dashboard',
	EQUINE_BUSINESS_GRANTS: 'equine_business_grants',
	EVENTS_CALENDAR: 'events_calendar',
	EVENTS: 'events',
	FULL_CIRCLE: 'full_circle',
	IM_NEW: 'im_new',
	JOIN_OTT_COMMUNITY: 'join_ott_community',
	LOADER_SPINNER: 'loader_spinner',
	LOGIN_HELPERS: 'login_helpers',
	LOGOUT: 'logout',
	MY_STABLE_HELPERS: 'my_stable_helpers',
	NEWS_TACK_ROOM: 'news_tack_room',
	NEWS: 'news',
	NOTES: 'notes',
	ONSITE_HUMANE_EUTHANASIA_PROGRAM: 'onsite_humane_euthanasia_program',
	OTT_EVENTS_SPONSORSHIP: 'ott_events_sponsorship',
	OTT_PARTNERSHIPS: 'ott_partnerships',
	OTT_SEARCH: 'ott_search',
	OTT_TACK_ROOM: 'ott_tack_room',
	POST_RACING_PROGRAMS: 'post_racing_programs',
	PROGRAM_EVENTS: 'program_events',
	PROGRAMS: 'programs',
	RESET: 'reset',
	SAVE_DRAFT: 'save_draft',
	SAVE: 'save',
	SEARCH_BY_NAME: 'search_by_name',
	SEARCH_MULTIPLE_THOROUGHBREDS: 'search_multiple_thoroughbreds',
	SEARCH_RETIREMENT_FARMS: 'search_retirement_farms',
	SEARCH_RETRAINER: 'search_retrainer',
	SEARCH_FOSTER_PROVIDER: 'search_foster_provider',
	SEARCH_THOROUGHBRED: 'search_thoroughbred',
	SEARCH: 'search',
	TOAST_MESSAGE: 'toast_message',
	TOAST_TYPE: 'toast_type',
	USER_NAME: 'user_name',
	NOTIFICATION: 'notification',
	DISMISS: 'dismiss',
	RIGHT_ANGLE: 'right_angle',
	YES: 'yes',
	START_APPLICATION: 'start_application',
	SUBMIT: 'submit',
	SUBURB: 'suburb',
	STATE: 'state',
	POSTCODE: 'postcode',
	COUNTRY: 'country',
	MANUALLY_ADD_ADDRESS: 'manually_add_address',
	EVENT_REPORTING_REVIEW_LIST: 'event_reporting_review_list',
	EVENT_REPORTING_CONTAINER: 'event_reporting_container',
	REQUEST_APPLICATION: 'request_application',
	LIFE_AFTER_RACING: 'life_after_racing',
	SAFETY_NET_PROGRAM: 'safety_net_program'
};

/** Register Page **/
export const RegisterKeys = {
	CONFIRM_PASSWORD: 'confirm_password',
	EMAIL: 'email',
	FIRST_NAME: 'first_name',
	LAST_NAME: 'last_name',
	PASSWORD: 'password',
	POSTCODE: 'postcode',
	PRIMARY_MEMBERSHIP_TYPE: 'primary_membership_type',
	RECAPTCHA_ANCHOR: 'recaptcha-anchor',
	REGISTER: 'register',
	SUBSCRIBE_EMAIL: 'subscribe_email',
	TERMS_AND_CONDITIONS: 'terms_and_conditions'
};

/** Login Page **/
export const LogInKeys = {
	EMAIL: 'email',
	FORGOT_PASSWORD: 'forgot_password',
	LOGIN: 'login',
	PASSWORD: 'password',
	REGISTER: 'register'
};

/** Dashboard Page **/
export const DashboardKeys = {
	WELCOME: 'welcome',
	UPDATE_PROFILE: 'update_profile',
	HOME: 'home',
	FOLLOWING: 'following',
	MY_STABLE: 'my_stable',
	VIEW_ALL_NOTIFICATIONS: 'view_all_notifications'
};

/** Horse Profile Page **/
export const HorseProfileKeys = {
	CLAIM_THOROUGHBRED: 'claim_thoroughbred',
	CONCLUDE_OHEP: 'conclude_ohep_option',
	CONCLUDE_RESET: 'conclude_reset_option',
	EDIT: 'edit',
	FOLLOW: 'follow_horse',
	FULL_CIRCLE: 'full_circle_option',
	NOMINATE_OHEP: 'nominate_ohep_option',
	NOMINATE_RESET: 'nominate_reset_option',
	NOTICE_TO_PROCEED: 'notice_to_proceed_option',
	OHEP_PROVIDERS: 'ohep_providers_option',
	REQUEST_RETRAINING: 'request_retraining_option',
	SHARE_INFO: 'share_info_option',
	UNCLAIM_THOROUGHBRED: 'unclaim_thoroughbred',
	TRANSITION_REPORT: 'transition_report_option'
};

/** Review List / Admin Page **/
export const ReviewListKeys = {
	FORM_MANAGEMENT: 'form_management',
	ROLE_MANAGEMENT: 'role_management',
	USER: 'user_search',
	COMPETITIONS: 'competitions',
	FOLLOWING_FEED: 'following_feed',
	CLAIM_THOROUGHBRED_FORMS: 'claim_thoroughbred_forms',
	CLAIM_THOROUGHBRED_FORMS_RESULT_CONTAINER:
		'claim_thoroughbred_forms_result_container',
	CONTACT_FORMS: 'contact_forms',
	CONTACT_FORMS_RESULT_CONTAINER: 'contact_forms_result_container',
	BUSINESS_PROFILE_ACKNOWLEDGED_RETRAINER_FORMS:
		'business_profile_acknowledged_retrainer_forms',
	BUSINESS_PROFILE_ACKNOWLEDGED_RETRAINER_FORMS_RESULT_CONTAINER:
		'business_profile_acknowledged_retrainer_forms_result_container',
	ACKNOWLEDGED_RETRAINER_REQUESTS_FORMS:
		'acknowledged_retrainer_requests_forms',
	ACKNOWLEDGED_RETRAINER_REQUESTS_FORMS_RESULT_CONTAINER:
		'acknowledged_retrainer_requests_forms_result_container',
	FULL_CIRCLE_FORMS: 'full_circle_forms',
	FULL_CIRCLE_FORMS_RESULT_CONTAINER: 'full_circle_forms_result_container',
	FIND_YOUR_THOROUGHBRED_FORMS: 'find_your_thoroughbred_forms',
	TRACEABILITY_REQUESTS: 'traceability_requests',
	FIND_YOUR_THOROUGHBRED_FORMS_RESULT_CONTAINER:
		'find_your_thoroughbred_forms_result_container',
	AVAILABLE_FOR_RETRAINING_FORMS: 'available_for_retraining_forms',
	AVAILABLE_FOR_RETRAINING_FORMS_RESULT_CONTAINER:
		'available_for_retraining_forms_result_container',
	OHEP_FORMS: 'ohep_forms',
	OHEP_FORMS_RESULT_CONTAINER: 'ohep_forms_result_container',
	RESET_FORMS: 'reset_forms',
	RESET_FORMS_RESULT_CONTAINER: 'reset_forms_result_container',
	SHARE_INFORMATION_FORMS: 'share_information_forms',
	SHARE_INFORMATION_FORMS_RESULT_CONTAINER:
		'share_information_forms_result_container',
	TRANSITION_SUPPORT_FORMS: 'transition_support_forms',
	TRANSITION_SUPPORT_FORMS_RESULT_CONTAINER:
		'transition_support_forms_result_container',
	SAFE_NET_PROGRAM_REPORTS_FORMS: 'safety_net_program_reports_forms',
	SAFE_NET_PROGRAM_REPORTS_CONTAINER: 'safety_net_program_reports_container',
	EMAIL: 'email',
	ROLES: 'roles',
	EVENT_ORGANISER: 'event_organiser',
	OTHER_ORGANISER: 'other_organiser',
	UPDATE_ROLE: 'update_role'
};

/** Search Page **/
export const SearchKeys = {
	THOROUGHBRED: 'thoroughbred',
	ACKNOWLEDGED_RETRAINERS: 'acknowledged_retrainers',
	AVAILABLE_FOR_RETRAINING: 'available_for_retraining',
	ACKNOWLEDGED_FOSTER_PROVIDERS: 'acknowledged_foster_providers'
};

/** Horse Management / My Stable Page **/
export const HorseManagementKeys = {
	CLAIMED: 'claimed',
	FULL_CIRCLE: 'my_stable_full_circle',
	PREVIOUS_CLAIM_FORM: 'previous_claim_form',
	LIST_OF_FOLLOWING_HORSE: 'list_of_following_horse',
	RETRAINING: 'retraining',
	RESET: 'my_stable_reset',
	ACKNOWLEDGED_RETIREMENT: 'acknowledged_retirement',
	ACKNOWLEDGED_FOSTER_PROVIDER: 'acknowledged_foster_provider',
	RESET_EXPRESS: 'reset_express',
	HORSE_NAME: 'name',
	FUNDED_BY: 'funded_by',
	FUNDED_BY_TOGGLE: 'funded_by_toggle',
	FUNDED_BY_SELF: 'funded_by_self',
	FUNDED_BY_CLIENT: 'funded_by_client',
	RETRAINING_STATUS: 'retraining_status',
	RETRAINING_STATUS_SELECT: 'retraining_status_select',
	CONCLUDED_REASON: 'concluded_reason',
	AS_OF_DATE: 'as_of_date',
	ARRIVAL_DATE: 'arrival_date',
	CONCLUDED_DATE: 'concluded_date',
	CHANGE_STATUS_CHECKBOX: 'change_status_checkbox',
	SUBMIT_REPORT: 'submit_report',
	DATE_COMMENCED: 'date_commenced',
	DATE_ADVERTIZED: 'date_advertized',
	DATE_SOLD: 'date_sold',
	PRICE_BRACKET: 'price_bracket',
	CONCLUDED_PURPOSE: 'concluded_purpose',
	CONCLUDED_PURPOSE_OTHER: 'concluded_purpose_other',
	CONCLUDED_DISCIPLINE: 'concluded_discipline',
	CONCLUDED_DISCIPLINE_OTHER: 'concluded_discipline_other',
	FULL_NAME: 'full_name',
	EMAIL: 'email',
	POSTCODE: 'postcode',
	FULL_CIRCLE_HORSE: 'full_circle_horse',
	HORSE_STAYING: 'horse_staying',
	TYPE_OF_ASSISTANCE: 'type_of_assistance',
	DATE_OF_DEATH: 'date_of_death',
	CAUSE_OF_DEATH: 'cause_of_death',
	EUTHANISED_REASON: 'euthanised_reason',
	EUTHANISED_INJURY_TYPE: 'euthanised_injury_type',
	EUTHANISED_ILLNESS_TYPE: 'euthanised_illness_type',
	EUTHANISED_OTHER_INJURY_OR_ILLNESS: 'euthanised_other_injury_or_illness',
	EUTHANISED_UNSUITABLE_TYPE: 'euthanised_unsuitable_type',
	EUTHANISED_OTHER_TYPE: 'euthanised_other_type',
	CONCLUDED_RETRAINING: 'concluded_retraining',
	DATE_RETURNED: 'date_returned',
	RETURN_TO_OWNER_REASON: 'return_to_owner_reason',
	RETURN_TO_OWNER_REASON_OTHER: 'return_to_owner_reason_other',
	CURRENT_ADDRESS: 'current_address',
	FORM: 'form',
	LAST_REPORT_SUBMITTED: 'last_report_submitted',
	DATE_OF_ASSESSMENT: 'date_of_assessment',
	INITIAL_LEFT_SIDE_PHOTO: 'initial_left_side_photo',
	INITIAL_RIGHT_SIDE_PHOTO: 'initial_right_side_photo',
	OVERALL_CONDITION: 'overall_condition',
	WORMING: 'worming',
	WORMING_DATE_OF_COMPLETION: 'worming_date_of_completion',
	FARRIER: 'farrier',
	FARRIER_DATE_OF_COMPLETION: 'farrier_date_of_completion',
	DENTIST: 'dentist',
	DENTIST_DATE_OF_COMPLETION: 'dentist_date_of_completion',
	OTHER_HUSBANDRY_INFO: 'other_husbandry_info',
	FEEDING_REQUIREMENT_PLAN: 'feeding_requirement_plan',
	TEMPERAMENT: 'temperament',
	GROUNDWORK_RIDDEN_ASSESSMENT: 'groundwork_ridden_assessment',
	REPURPOSING: 'repurposing',
	TIMEFRAME: 'timeframe',
	BEHAVIOURAL_REASON: 'behavioural_reason',
	BEHAVIOURAL_REASON_EXPLANATION: 'behavioural_reason_explanation',
	WEEK_NUMBER_DROPDOWN: 'week_number_dropdown',
	WEEK_NUMBER_TEXT: 'week_number_text',
	HORSE_STATUS_CHANGE: 'horse_status_change',
	NEW_STATUS: 'new_status',
	WEEKLY_PROGRESS_PHOTOS: 'weekly_progress_photos',
	COMPLETE_REMAINDER: 'complete_remainder',
	WEEK_HORSE_WORK: 'week_horse_work',
	WEEK_WORK_SESSIONS: 'week_work_sessions',
	KEY_PROGRESS: 'key_progress',
	NEED_EXTRA_WORK: 'need_extra_work',
	WORK_PLAN: 'work_plan',
	LAMENESS: 'lameness',
	LAMENESS_DETAILS: 'lameness_details',
	RETRAIN: 'retrain',
	VET_ATTENTION: 'vet_attention',
	VET_ATTENTION_DETAILS: 'vet_attention_details',
	OTHER_CONCERNS: 'other_concerns',
	HORSE_PROGRESS_UPDATE: 'horse_progress_update',
	HORSE_ISSUES_ENCOUNTERED: 'horse_issues_encountered',
	QUARTER_NUMBER: 'quarter_number',
	YEAR: 'year',
	QUARTERLY_PROGRESS_PHOTOS: 'quarterly_progress_photos',
	VET_CLEARED_DOCUMENTS: 'vet_cleared_documents',
	REQUIRE_ONGOING_ATTENTION: 'require_ongoing_attention',
	FINAL_COMMENTS: 'final_comments',
	DATE_STABLED: 'date_stabled',
	STATUS: 'status',
	VICES: 'vices',
	OTHER: 'other',
	PHYSICAL_HEALTH_CONCERN: 'physical_health_concern',
	CONCERN: 'concern',
	BEHAVIOUR_CONCERN: 'behaviour_concern',
	PROVIDE_MORE_INFORMATION: 'provide_more_information',
	PHYSICAL_HEALTH_CHANGES: 'physical_health_changes',
	PHYSICAL_CHANGE: 'physical_change',
	PLEASE_SPECIFY: 'please_specify',
	BEHAVIOUR_CHANGES: 'behaviour_changes',
	RECEIVED_ANY_TREATMENTS: 'received_any_treatments',
	REHABILITATION_REASON: 'rehabilitation_reason',
	OTHER_REHABILITATION_REASON: 'other_rehabilitation_reason',
	INJURIES: 'injuries',
	ILLNESS: 'illness',
	REHABILITATION_COMMENCED: 'rehabilitation_commenced',
	OWNER_BEEN_NOTIFIED: 'owner_been_notified',
	INJURY_DETAILS: 'injury_details',
	INJURY_OTHER_DETAILS: 'injury_other_details',
	ILLNESS_DETAILS: 'illness_details',
	ILLNESS_OTHER_DETAILS: 'illness_other_details',
	OTHER_DETAILS: 'other_details',
	TIE_UP: 'tie_up',
	RECEIVED_TRAINING: 'received_training',
	RECEIVED_EXERCISE: 'received_exercise',
	TRANSPORTED: 'transported',
	SOUND: 'sound',
	CLEARED_BY_VET: 'cleared_by_vet',
	COMMENCE_RIDDEN: 'commence_ridden',
	ATTENDED: 'attended',
	SHOD: 'shod',
	TREATED_DENTIST: 'treated_dentist',
	WEEKS_TO_RETRAIN: 'weeks_to_retrain'
};

/** Claim Thoroughbred Form / Review Page **/
export const ClaimThoroughbredFormKeys = {
	RELATIONSHIP: 'relationship',
	PURPOSE_OF_CLAIM: 'purpose_of_claim',
	ORGANISATION_NAME: 'organisation_name',
	RESIDENTIAL_ADDRESS_LINE_1: 'residential_address_line_1',
	RESIDENTIAL_ADDRESS_LINE_2: 'residential_address_line_2',
	RESIDENTIAL_SUBURB: 'residential_suburb',
	RESIDENTIAL_POSTCODE: 'residential_postcode',
	RESIDENTIAL_STATE: 'residential_state',
	RESIDENTIAL_COUNTRY: 'residential_country',
	IS_YOUR_THOROUGHBRED_AT_THIS_ADDRESS: 'is_your_thoroughbred_at_this_address',
	THOROUGHBRED_ADDRESS: 'thoroughbred_address',
	THOROUGHBRED_SUBURB: 'thoroughbred_suburb',
	THOROUGHBRED_STATE: 'thoroughbred_state',
	THOROUGHBRED_POSTCODE: 'thoroughbred_postcode',
	THOROUGHBRED_COUNTRY: 'thoroughbred_country',
	DATE_ARRIVED: 'date_arrived',
	HOW_DID_YOU_ACQUIRE_THIS_HORSE: 'how_did_you_acquire_this_horse',
	HOW_DID_YOU_ACQUIRE_THIS_HORSE_OTHER: 'how_did_you_acquire_this_horse_other',
	MANUALLY_ADD_THOROUGHBRED_ADDRESS: 'manually_add_thoroughbred_address',
	HORSE_PROGRAM: 'horse_program',
	BRAND_PHOTO_LEFT: 'brand_photo_left',
	BRAND_PHOTO_RIGHT: 'brand_photo_right',
	MARKINGS_PHOTO_SIDE: 'markings_photo_side',
	MARKINGS_PHOTO_FRONT: 'markings_photo_front',
	MICROCHIP_DOCUMENT: 'microchip_document',
	FOAL_YEAR: 'foal_year',
	MICROCHIP_NUMBER: 'microchip_number',
	SIRE_NAME: 'sire_name',
	DAM_NAME: 'dam_name',
	CANCEL_REQUEST: 'cancel_request',
	SUBMIT_REQUEST: 'submit_request',
	REASON_FOR_REJECTION: 'reason_for_rejection',
	REJECT: 'reject',
	APPROVE: 'approve'
};

/** Full Circle Form **/
export const FullCircleFormKeys = {
	PHONE_NUMBER_BUSINESS: 'phone_number_business',
	PHONE_NUMBER_PERSONAL: 'phone_number_personal',
	RELATIONSHIP_TO_HORSE: 'relationship_to_horse',
	TYPE_OF_ASSISTANCE: 'type_of_assistance'
};

/** Account / Profile **/
export const ProfileKeys = {
	ADVISE_UNAVAILABLE_DATE: 'advise_unavailable_date',
	ALTERNATIVE_CONTACT: 'alternative_contact',
	ALTERNATIVE_PHONE: 'alternative_phone',
	BUSINESS_DETAILS: 'business_details',
	BUSINESS_EMAIL: 'business_email',
	BUSINESS_NAME: 'business_name',
	BUSINESS_PHONE: 'business_phone',
	BUSINESS_PROFILE: 'business_profile',
	END_DATE: 'end_date',
	EXPERIENCE: 'experience',
	FACILITIES_OFF_PROPERTY: 'facilities_off_property',
	FACILITIES_ON_PROPERTY: 'facilities_on_property',
	FACILITIES_RIDDEN: 'facilities_ridden',
	FINAL_NOTES: 'final_notes',
	FIRST_NAME: 'first_name',
	FOSTER_PROVIDER_INFORMATION: 'foster_provider_information',
	GENERAL: 'general',
	LAST_NAME: 'last_name',
	MANUAL_ADDRESS: 'manual_address',
	MAXIMUM_CAPACITY_DETAILS: 'maximum_capacity_details',
	MAXIMUM_CAPACITY: 'maximum_capacity',
	MEDIA: 'media',
	OTHER_FACILITIES_OFF_PROPERTY: 'other_facilities_off_property',
	OTHER_FACILITIES_ON_PROPERTY: 'other_facilities_on_property',
	PERSONAL_PROFILE: 'personal_profile',
	POSTCODE: 'postcode',
	PROFILE_TYPE: 'profile_type',
	PROPERTY: 'property',
	RANGE_FOSTER_CARE: 'range_foster_care',
	START_DATE: 'start_date',
	STATE: 'state',
	STREET_ADDRESS: 'street_address',
	SUBMIT_FOR_REVIEW: 'submit_for_review',
	SUBURB: 'suburb'
};

/** Acknowledged Retirement & Retrainer Application Form **/
export const AcknowledgedAppFormKeys = {
	PERSONAL_DETAILS_FORM: 'personal_details_form',
	BUSINESS_DETAILS_FORM: 'business_details_form',
	BUSINESS_SUPPLIERS_FORM: 'business_suppliers_form',
	CREDIT_REFERENCES_FORM: 'credit_references_form',
	STAFF_DETAILS_FORM: 'staff_details_form',
	INSURANCE_DETAILS_FORM: 'insurance_details_form',
	EXPERIENCE_FORM: 'experience_form',
	CURRENT_RV_LICENCES_FORM: 'current_rv_licences_form',
	PREVIOUS_RV_LICENCES_FORM: 'previous_rv_licences_form',
	DECLARATIONS_FORM: 'declarations_form',
	ACKNOWLEDGEMENTS_FORM: 'acknowledgements_form',
	FIRST_NAME: 'first_name',
	LAST_NAME: 'last_name',
	EMAIL: 'email',
	DATE_OF_BIRTH: 'date_of_birth',
	CONTACT_NUMBER: 'contact_number',
	RESIDENTIAL_ADDRESS: 'residential_address',
	RESIDENTIAL_SUBURB: 'residential_suburb',
	RESIDENTIAL_POSTCODE: 'residential_postcode',
	RESIDENTIAL_STATE: 'residential_state',
	RESIDENTIAL_COUNTRY: 'residential_country',
	MANUAL_RESIDENTIAL_ADDRESS: 'manual_residential_address',
	DIFFERENT_POSTAL_ADDRESS: 'different_postal_address',
	POSTAL_ADDRESS: 'postal_address',
	POSTAL_SUBURB: 'postal_suburb',
	POSTAL_POSTCODE: 'postal_postcode',
	POSTAL_STATE: 'postal_state',
	POSTAL_COUNTRY: 'postal_country',
	MANUAL_POSTAL_ADDRESS: 'manual_postal_address',
	STABLE_ADDRESS: 'stable_address',
	STABLE_SUBURB: 'stable_suburb',
	STABLE_POSTCODE: 'stable_postcode',
	STABLE_STATE: 'stable_state',
	STABLE_COUNTRY: 'stable_country',
	MANUAL_STABLE_ADDRESS: 'manual_stable_address',
	SAME_STABLE_AS_RESIDENTIAL_ADDRESS: 'same_stable_as_residential_address',
	I_OWN_STABLE_ADDRESS: 'i_own_stable_address',
	VALID_ID: 'valid_id',
	OPERATION_RUN_UNDER_COMPANY: 'operation_run_under_company',
	BUSINESS_STRUCTURE: 'business_structure',
	COMPANY_BUSINESS_NAME: 'company_business_name',
	ABN: 'abn',
	OWN_ALL_SHARES_IN_THAT_COMPANY: 'own_all_shares_in_that_company',
	ADDITIONAL_OWN_ALL_SHARES: 'additional_own_all_shares',
	TRUSTEE_NAME: 'trustee_name',
	TRUSTEE_ACN: 'trustee_acn',
	TRUSTEE_OWN_ALL_SHARES_IN_THAT_COMPANY:
		'trustee_own_all_shares_in_that_company',
	ADDITIONAL_TRUST_OWN_ALL_SHARES: 'additional_trust_own_all_shares',
	PARTNER_NAME: 'partner_name',
	PARTNER_DETAILS: 'partner_details',
	REMOVE_ENTRY: 'remove_entry',
	ADD_ENTRY: 'add_entry',
	ADDITIONAL_BUSINESS_DETAILS: 'additional_business_details',
	ADDITIONAL_EMPLOYMENT: 'additional_employment',
	TYPE_OF_EMPLOYMENT: 'type_of_employment',
	ADDITIONAL_EMPLOYMENT_TYPE_DETAILS: 'additional_employment_type_details',
	OTHER_BUSINESS_ACTIVITIES: 'other_business_activities',
	OTHER_BUSINESS_ACTIVITY_DETAILS: 'other_business_activity_details',
	VETERINARY_NAME: 'veterinary_name',
	VETERINARY_REGULAR: 'veterinary_regular',
	VETERINARY_ADDRESS: 'veterinary_address',
	VETERINARY_PHONE: 'veterinary_phone',
	VETERINARY_EMAIL: 'veterinary_email',
	BUSINESS_FARRIER: 'business_farrier',
	FARRIER_PHONE: 'farrier_phone',
	FARRIER_EMAIL: 'farrier_email',
	CREDIT_REFERENCES: 'credit_references',
	HAVE_ANY_STAFF: 'have_any_staff',
	STAFF_FULL_NAME: 'staff_full_name',
	STAFF_ROLE_OTHER: 'staff_role_other',
	STAFF_OTHER_ROLE: 'staff_other_role',
	PUBLIC_LIABILITY_POLICY_NUMBER: 'public_liability_policy_number',
	PUBLIC_LIABILITY_EXPIRY: 'public_liability_expiry',
	PUBLIC_LIABILITY_COVERAGE: 'public_liability_coverage',
	PUBLIC_LIABILITY_INSURER_NAME: 'public_liability_insurer_name',
	PUBLIC_LIABILITY_INSURED_PARTY_NAME: 'public_liability_insured_party_name',
	PROFESSIONAL_INDEMNITY: 'professional_indemnity',
	WORKER_COMPENSATION: 'worker_compensation',
	INSURANCE_CERTIFICATE_OF_CURRENCY: 'insurance_certificate_of_currency',
	INDEMNITY_INSURER_NAME: 'indemnity_insurer_name',
	INDEMNITY_INSURED_PARTY_NAME: 'indemnity_insured_party_name',
	PROFESSIONAL_INDEMNITY_POLICY_NUMBER: 'professional_indemnity_policy_number',
	PROFESSIONAL_INDEMNITY_EXPIRY: 'professional_indemnity_expiry',
	PROFESSIONAL_INDEMNITY_COVERAGE: 'professional_indemnity_coverage',
	WORKERS_COMPENSATION_INSURED_PARTY_NAME:
		'workers_compensation_insured_party_name',
	WORKER_COMPENSATION_POLICY_NUMBER: 'worker_compensation_policy_number',
	WORKER_COMPENSATION_EXPIRY: 'worker_compensation_expiry',
	WORKER_COMPENSATION_COVERAGE: 'worker_compensation_coverage',
	RIDING_EXPERIENCE: 'riding_experience',
	LIST_MEMBERSHIP: 'list_membership',
	OTHER_MEMBERSHIP: 'other_membership',
	ACK_RETIREMENT_FARM_SUPPLIER_QUESTION:
		'ack_retirement_farm_supplier_question',
	EXPECTATION_FROM_RV_QUESTION: 'expectation_from_rv_question',
	FORMAL_AGREEMENT_IN_PLACE: 'formal_agreement_in_place',
	OUTLINE_APPROACH_QUESTION: 'outline_approach_question',
	OUTLINE_RESPONSIBILITY_QUESTION: 'outline_responsibility_question',
	CLIENT_REF_UPLOADS: 'client_ref_uploads',
	AGISTMENT_FACILITIES_UPLOADS: 'agistment_facilities_uploads',
	CURRENT_LICENSE_TYPE: 'current_license_type',
	CURRENT_LICENSE_NUMBER: 'current_license_number',
	CURRENT_LICENSE_START_DATE: 'current_license_start_date',
	PREVIOUS_LICENSE_TYPE: 'previous_license_type',
	PREVIOUS_LICENSE_NUMBER: 'previous_license_number',
	PREVIOUS_LICENSE_START_DATE: 'previous_license_start_date',
	SUBJECTED_TO_ANIMAL_WELFARE: 'subjected_to_animal_welfare',
	SUBJECTED_TO_ANIMAL_WELFARE_DETAILS: 'subjected_to_animal_welfare_details',
	ANY_BAN: 'any_ban',
	ANY_BAN_DETAILS: 'any_ban_details',
	DISQUALIFIED: 'disqualified',
	DISQUALIFIED_DETAILS: 'disqualified_details',
	GUILTY_OF_OFFENSE: 'guilty_of_offense',
	GUILTY_OF_OFFENSE_DETAILS: 'guilty_of_offense_details',
	ANY_CRIMINAL_CHARGES: 'any_criminal_charges',
	ANY_CRIMINAL_CHARGES_DETAILS: 'any_criminal_charges_details',
	BANKRUPTCY: 'bankruptcy',
	BANKRUPTCY_DETAILS: 'bankruptcy_details',
	READ_AND_AGREE: 'read_and_agree',
	SUPPORT_PROMOTE_EQUINE_WELFARE: 'support_promote_equine_welfare',
	POLICE_RECORD_CERT: 'police_record_cert',
	CRIMINAL_CHARGES: 'criminal_charges',
	PERSONAL_BANKRUPTCY: 'personal_bankruptcy',
	UTILISE_TO_SUBMIT_REPORT: 'utilise_to_submit_report',
	DECLARE_APPLICATION_TRUE_AND_CORRECT: 'declare_application_true_and_correct',

	/** Acknowledged Retrainer Application Form **/
	RETRAINING_DETAILS_FORM: 'retraining_details_form',
	REHOMING_HISTORY_FORM: 'rehoming_history_form',
	RETRAINING_PROCESS_FORM: 'retraining_process_form',
	HOW_YOU_SOURCE_HORSES: 'how_you_source_horses',
	OTHER_HOW_YOU_SOURCE_HORSES: 'other_how_you_source_horses',
	RETRAINER_DISCIPLINES: 'retrainer_disciplines',
	OTHER_RETRAINER_DISCIPLINES: 'other_retrainer_disciplines',
	NUMBER_OF_HORSES_REHOMED: 'number_of_horses_rehomed',
	DETAILS_OF_REHOMING: 'details_of_rehoming',
	LIST_NAMES_OF_RETRAINED_HORSES: 'list_names_of_retrained_horses',
	CAPACITY_TO_RETRAIN: 'capacity_to_retrain',
	WHY_BECOME_RETRAINER: 'why_become_retrainer',
	EXPECTED_SUPPORT: 'expected_support',
	RETRAINING_PROCESS: 'retraining_process',
	STEPS_TAKEN_FOR_LONG_TERM_PLACEMENT: 'steps_taken_for_long_term_placement',
	SIGNED_REFERENCE: 'signed_reference',
	UNSUITABLE_HORSE_TO_RETRAIN: 'unsuitable_horse_to_retrain',
	BUSINESS_MODEL: 'business_model',
	ASSIST_HORSE: 'assist_horse',
	RIDING_COMPETITION_EXPERIENCE: 'riding_competition_experience',
	RECEIVE_COACHING: 'receive_coaching',
	COACH_NAME: 'coach_name',
	QUALIFIED_COACH: 'qualified_coach',
	RELEVANT_QUALIFICATION: 'relevant_qualification',
	EDUCATIONAL_ACHIEVEMENT: 'educational_achievement',
	RESUME: 'resume',
	ADD_VIDEO_LINK: 'add_video_link',
	VIDEO_LINK: 'video_link'
};

/** RESET Nomination Application Form **/
export const ResetNominationFormKeys = {
	WHAT_IS_RESET: 'what_is_reset',
	THOROUGHBRED_DETAILS_FORM: 'thoroughbred_details_form',
	CONTACT_DETAILS_FORM: 'contact_details_form',
	THOROUGHBRED_INJURY_AND_HEALTH_FORM: 'thoroughbred_injury_and_health_form',
	THOROUGHBRED_INFORMATION_FORM: 'thoroughbred_information_form',
	THOROUGHBRED_PHOTOS_FORM: 'thoroughbred_photos_form',
	REHOMING_DECLARATION_FORM: 'rehoming_declaration_form',
	PROGRAM_DECLARATION_FORM: 'program_declaration_form',
	VETERINARY_EXAMINATION_FORM: 'veterinary_examination_form',
	REGISTERED_OWNER: 'registered_owner',
	REGISTERED_MANAGING_OWNER: 'registered_managing_owner',
	PROOF_OWNER: 'proof_owner',
	PROOF_OF_PURCHASE_DOCUMENTS: 'proof_of_purchase_documents',
	SIRE_NAME: 'sire_name',
	DAM_NAME: 'dam_name',
	APPROX_HORSE_HEIGHT: 'approx_horse_height',
	REASON_FOR_RETIREMENT: 'reason_for_retirement',
	OTHER_REASON_FOR_RETIREMENT: 'other_reason_for_retirement',
	CONTACT_NUMBER: 'contact_number',
	RESIDENTIAL_ADDRESS: 'residential_address',
	RESIDENTIAL_SUBURB: 'residential_suburb',
	RESIDENTIAL_POSTCODE: 'residential_postcode',
	RESIDENTIAL_STATE: 'residential_state',
	RESIDENTIAL_COUNTRY: 'residential_country',
	MANUAL_RESIDENTIAL_ADDRESS: 'manual_residential_address',
	RELATIONSHIP: 'relationship',
	OTHER_RELATIONSHIP: 'other_relationship',
	RELATIONSHIP_LENGTH: 'relationship_length',
	CURRENT_OWNERS: 'current_owners',
	CURRENT_TRAINERS: 'current_trainers',
	WIND_SUCK: 'wind_suck',
	HAS_HORSE_BEEN_INJURED: 'has_horse_been_injured',
	VET_REPORTS: 'vet_reports',
	INJURY_TYPES: 'injury_types',
	INJURY_NATURE: 'injury_nature',
	HERITABLE_DISORDER: 'heritable_disorder',
	HERITABLE_DISORDER_DETAILS: 'heritable_disorder_details',
	DISEASE: 'disease',
	DISEASE_DETAILS: 'disease_details',
	ACCIDENTS: 'accidents',
	ACCIDENTS_DETAILS: 'accidents_details',
	EYE_DISEASE: 'eye_disease',
	EYE_DISEASE_DETAILS: 'eye_disease_details',
	LAMENESS: 'lameness',
	LAMENESS_DETAILS: 'lameness_details',
	HEAD_SHAKING: 'head_shaking',
	HEAD_SHAKING_DETAILS: 'head_shaking_details',
	VICES: 'vices',
	VICES_DETAILS: 'vices_details',
	ABNORMALITY: 'abnormality',
	ABNORMALITY_DETAILS: 'abnormality_details',
	SURGICAL: 'surgical',
	SURGICAL_DETAILS: 'surgical_details',
	VACCINATION: 'vaccination',
	VACCINATION_DETAILS: 'vaccination_details',
	BREEDING: 'breeding',
	BREEDING_DETAILS: 'breeding_details',
	TEMPERAMENT: 'temperament',
	DISCIPLINES: 'disciplines',
	OTHER_DISCIPLINES: 'other_disciplines',
	RIDDEN_HISTORY: 'ridden_history',
	VICES_BEHAVIOUR: 'vices_behaviour',
	FACE_ON: 'face_on',
	LEFT_SIDE: 'left_side',
	RIGHT_SIDE: 'right_side',
	BEHIND: 'behind',
	ADDITIONAL: 'additional',
	REHOMING_ATTEMPT: 'rehoming_attempt',
	NO_BEHAVIOURAL_ISSUES: 'no_behavioural_issues',
	NO_HEALTH_ISSUES: 'no_health_issues',
	RESPONSIBLE_OWNER: 'responsible_owner',
	CANT_BE_REHOMED: 'cant_be_rehomed',
	REHOMING_PROGRAM: 'rehoming_program',
	AGREE_COMPLETION_OF_FORM: 'agree_completion_of_form',
	DISABLED_ASSOCIATION: 'disabled_association',
	SALE_PRICE: 'sale_price',
	RV_AGREEMENT: 'rv_agreement',
	PRIVACY_STATEMENT: 'privacy_statement',
	CONCENT: 'concent'
};

/** Conclude RESET Form **/
export const ResetConcludeFormKeys = {
	DATE_EUTHANISED: 'date_euthanised',
	EUTHANISED_REASON: 'euthanised_reason',
	OTHER_EUTHANISED_REASON: 'other_euthanised_reason',
	EUTHANISED_INJURY: 'euthanised_injury',
	DATE_SOLD_REHOMED: 'date_sold_rehomed',
	PRICE: 'price',
	REHOMING_PURPOSE: 'rehoming_purpose',
	OTHER_REHOMING_PURPOSE: 'other_rehoming_purpose',
	DISCIPLINES: 'disciplines',
	POSTCODE: 'postcode',
	DATE_RETURNED: 'date_returned',
	RETURNED_REASON: 'returned_reason',
	OTHER_RETURNED_REASON: 'other_returned_reason',
	RETURNED_INJURY: 'returned_injury',
	INJURIES_IMPACTING: 'injuries_impacting',
	ILLNESSES_IMPACTING: 'illnesses_impacting',
	PRE_EXISTING_INJURIES_NOTED: 'pre_existing_injuries_noted',
	INJURIES_NOTED: 'injuries_noted',
	PRE_EXISTING_INJURIES_NOT_NOTED: 'pre_existing_injuries_not_noted',
	INJURIES_NOT_NOTED: 'injuries_not_noted',
	ANY_INJURIES_THE_HORSE_LEAVING: 'any_injuries_the_horse_leaving',
	INJURIES_LEAVING: 'injuries_leaving',
	PRE_EXISTING_ILLNESS_NOTED: 'pre_existing_illness_noted',
	ILLNESS_NOTED: 'illness_noted',
	PRE_EXISTING_ILLNESS_NOT_NOTED: 'pre_existing_illness_not_noted',
	ILLNESS_NOT_NOTED: 'illness_not_noted',
	ANY_ILLNESS_THE_HORSE_LEAVING: 'any_illness_the_horse_leaving',
	ILLNESS_LEAVING: 'illness_leaving'
};

/** Event Request Report */
export const EventRequestReportKeys = {
	EVENT_ORGANISER: 'event_organiser',
	DATE_REQUIRED: 'date_required'
};

/** Sponsorship Outcome Form */
export const SponsorshipOutcomeFormKeys = {
	PRIZE_MONEY_DISTRIBUTED: 'prize_money_distributed',
	PATHWAYS_ACTIVATED: 'pathways_activated',
	INCREASED_UPSTAKE: 'increased_upstake',
	HAS_SPONSORSHIP_MET_OBJECTIVE: 'has_sponsorship_met_objective',
	ADDITIONAL_IMPROVEMENTS_REQUIRED: 'additional_improvements_required',
	RECOMMEND_TO_RENEW_SPONSORSHIP: 'recommend_to_renew_sponsorship'
};

export const BusinessProfileType = [
	{
		id: 1,
		value: 'Acknowledged Retrainer'
	},
	{
		id: 2,
		value: 'Acknowledged Retirement Farm'
	},
	{
		id: 3,
		value: 'Acknowledged STAR Provider'
	}
];

export const BusinessProfileTypeAckRetrainerRequest = [
	{
		id: 2,
		value: 'Acknowledged Retrainer'
	},
	{
		id: 7,
		value: 'Acknowledged Retirement Farm'
	},
	{
		id: 3,
		value: 'Acknowledged STAR Provider'
	}
];

export const SafetyNetProgramReportsType = [
	{
		id: 1,
		value: 'Retirement Initial Assessment'
	},
	{
		id: 2,
		value: 'Retirement Quarterly Assessment'
	},
	{
		id: 3,
		value: 'STAR Initial Assessment'
	},
	{
		id: 4,
		value: 'STAR Weekly Assessment'
	}
];

export const RETIREMENT_NOTIFICATION_SOURCE = 'Admin RETIREMENT Rejection';

export const HorseStatus = [
	{
		id: 6,
		value: 'Available for Retraining'
	},
	{
		id: 7,
		value: 'Eligible for RESET'
	},
	{
		id: 8,
		value: 'RESET 2 horses'
	}
];

export const HorseStatusType = {
	AvailableForRetraining: 6,
	EligibleForReset: 7,
	ResetExpressHorses: 8
};
export const RV_FOSTER_PROGRAM_ID = 34;
export const ON_SITE = 'on_site';
export const OFF_SITE = 'off_site';

export const ACK_FOSTER_FILTER_OPT = [
	{
		id: 25,
		value: 'Rehabilitation'
	},
	{
		id: 26,
		value: 'Rehomed'
	},
	{
		id: 27,
		value: 'Spelling'
	}
];

export const RESET_EXPRESS_FILTER_OPT = [
	// {
	// 	id: 37,
	// 	value: 'Listed, B1'
	// },
	// {
	// 	id: 38,
	// 	value: 'Listed, B2'
	// },
	// {
	// 	id: 39,
	// 	value: 'Listed, C'
	// },
	// {
	// 	id: 40,
	// 	value: 'Removed listing'
	// },
	{
		id: 41,
		value: 'In Retraining'
	},
	{
		id: 42,
		value: 'Spelling'
	},
	{
		id: 43,
		value: 'Rehabilitation'
	},
	{
		id: 44,
		value: 'Ready for rehoming'
	}
];

export const DOCUMENT_TYPES = [
	{ id: null, value: '' },
	{ id: 1, value: 'Passport' },
	{ id: 2, value: 'Driver Licence' }
];

export const AVAILABLE_FOR_RETRAINING_APPROVED =
	'Available for retraining approved';
export const RETRAINER_IS_INTERESTED = 'Retrainer is interested';

export const LIST_OF_NOTIFICATION_TO_BE_DISMISSABLE = [
	HORSE_CLAIM,
	FIND_THOROUGHBRED,
	FULL_CIRCLE,
	HORSE_PLACEMENT,
	HORSE_PLACEMENT_REJECTED,
	SHARE_INFORMATION,
	SHARE_INFORMATION_REJECTED
];

/** Retrainer Profile List / Retrainer Profile Page **/
export const RetrainerProfileListKeys = {
	PROFILE: 'retrainer_profile',
	HORSE_FOR_SALE: 'retrainer_horse_for_sale'
};

export const B2_INJURY_LISTING = [
	3, //Fracture
	13, //Kissing Spine
	15 //Arthritis
];

export const B2_VICES_LISTING = [
	3, //Bucking
	8 //Rearing
];

export const C_INJURY_LISTING = [
	11 //General Soreness
];

export const ROLE_ID = {
	OTT_COMMUNITY: 1,
	RSPCA_REPRESENTATIVE: 2,
	SPECIALISED_FACILITY: 3,
	MOUNTED_POLICE_FORCE: 4,
	ACK_RETRAINER: 5,
	ACK_STAR_CARER: 6,
	ACK_RETIREMENT_FARM: 7,
	RV_EQUINE_WELFARE: 8,
	NON_RV_VET: 9,
	RV_VET: 10,
	EQUESTRIAN_ORG: 11,
	COMPETITION_ADMIN: 12,
	TRANSPORTATION: 13,
	KNACKERY_REMOVAL: 14,
	MERCHANDISE_SUPPLIER: 15,
	ACK_RESET_RETRAINER: 16,
	ACK_REHOMER: 17
};
